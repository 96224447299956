<template>
    <div class="flex flex-v flex-align-center flex-pack-center frame" style="height:100%;" ref="frame" :class="{mobile:isMobile}">
        <div>
            <div class="login-title">欢迎登录<span style="display: none;">中国</span>家居产业智联网</div>
            <div class="login flex">
                <div class="login-left-bg">
                    <img src="../../../assets/images/login/denglu_bg.jpg" alt="">
                </div>
                <div class="login-right flex-1">
                    <div class="flex flex-justify-around login-tab">
                        <div v-bind:class="{ active: activeName=='account' }" @click="activeName='account'">账号登录</div>
                        <div v-bind:class="{ active: activeName=='sms' }" @click="activeName='sms'">短信登录</div>
                    </div>
                    <div style="margin-top: 30px;">
                        <AccountLogin v-if="activeName=='account'" @success="onLoginSuccess" @register="onRegister" @onForget="onForget"></AccountLogin>
                        <SmsLogin  v-if="activeName=='sms'" :phone="phone" @success="onLoginSuccess" @register="onRegister" @onForget="onForget"></SmsLogin>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
    import AccountLogin from '@/components/login/account.vue';
    import SmsLogin from '@/components/login/sms.vue';
    import { surveyGet } from '@/service/mobile';
    export default {
        components: { AccountLogin, SmsLogin },
        data() {
            return {
                activeName: 'account',
                phone:null,
                isMobile:false
            }
        },
        created() {
            if (this.$route.query.type) {
                this.activeName = this.$route.query.type;
                this.phone = this.$route.query.phone;
            }
        },
        mounted(){
            this.isMobile = this.$refs['frame'].offsetWidth<600;
            window.onresize = () => {
                this.isMobile = this.$refs['frame'].offsetWidth<600;
            }
        },
        methods: {
            onLoginSuccess(){
                // 备料调研问卷入口
                // if (this.isMobile) {
                //     this.$router.push('/mobile/survey');
                //     surveyGet().then(rst => {
                //         console.log("survey",rst);
                //         this.survey = rst;
                //         if (rst && rst.step < 4 || !rst) {
                //             this.$router.push('/mobile/survey');
                //         }
                //         if (rst && rst.step == 4) this.$router.push('/center');
                //     })
                // } else {
                //     this.$router.push('/center');
                // }
                this.$router.push('/center');
            },
            onRegister(){
                if(this.isMobile){
                    this.$router.push('/mobile/register');
                }else{
                    this.$router.push('/register');
                }
            },
            onForget(){
                if(this.isMobile){
                    this.$router.push('/mobile/forget');
                }else{
                    this.$router.push('/forget');
                }
            }
        },
        destroyed(){
            window.onresize = null;
        }
    }

</script>
<style scoped>
    .login {
        width: 800px;
        /* height: 560px; */
        align-self: center;
        background:rgba(255,255,255,1);
        box-shadow:0px 4px 8px 0px rgba(200,207,228,1);
    }
    .login-title {
        text-align:center;
        color:#292929;
        font-size:24px;
        padding-top:60px;
        padding-bottom:80px;
        font-weight:bold;
    }
    .login-left-bg {
        width: 400px;
        /* height: 100%; */
    }
    .login-left-bg>img {
        width: 100%;
        height: 100%;
    }
    .login-right {
        padding: 60px 50px;
        height: 519px;
    }
    .login-tab>div{
        cursor: pointer;
        font-size:16px;
        padding: 7px 0;
    }
    .login-tab>div.active{
        color: #5074EE;
        border-bottom: 2px solid #5074EE;
    }
    .frame.mobile .login-title{
      display: none;
    }
    .frame.mobile .login{
        width: auto;
        box-shadow: none;
    }
    .frame.mobile .login-left-bg{
      display: none;
    }
</style>